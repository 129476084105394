.header-main__link {
  color: #000;
}

.mobile-logo {
  display: none;
}

/* .header-main {
  position: relative;
} */

@media (max-width: 500px) {
  .mobile-logo {
    display: block;
  }

  .header-logo {
    display: none;
  }
}
