.header {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  width: 100%;
  z-index: 99999999;
}

.header__burger-button {
  display: none;
}

.burger-menu {
  display: none;
}

.header__burger-button-active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background: url('../../img/burgerintro.png') no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 600px;
  z-index: 999999999;
}

@media (max-width: 1024px) {
  .header__burger-button {
    display: block;
  }

  .header__group {
    display: flex;
  }

  .header-button {
    margin-right: 20px;
  }

  .tablet-button {
    width: 170px !important;
    height: 25px !important;
    font-size: 14px !important;
    border-radius: 17px !important;
  }

  .burger-menu-container {
    position: relative;
    padding: 0 30px;
    padding-top: 100px;
  }

  .burger-menu-close {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .burger-menu-list {
    margin-bottom: 50px;
  }

  .burger-menu-item:not(:last-child) {
    margin-bottom: 30px;
  }

  .burger-menu-link {
    font-size: 32px;
    line-height: normal;
    font-weight: 600;
    color: var(--white);
  }

  .burger-menu-contacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .burger-menu-phone {
    font-size: 14px;
    color: var(--white);
  }

  .burger-button-contact {
    margin-top: 0px !important;
    border-color: var(--white) !important;
    background-color: transparent !important;
    width: 206px;
    height: 37px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 26px 0;
  }
}
