.options {
  margin-top: 176px;
  margin-bottom: 200px;
}

.options__main-title {
  margin-bottom: 45px;
}

.options__block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 596px;
  grid-column-gap: 25px;
  grid-row-gap: 0px;
}

.options__item {
  border-radius: 20px;
  padding: 35px 50px 45px 50px;
  background-color: #f0f0f0;
}

.red-item {
  background-color: #ff0000;
}

.options__title {
  font-size: 36px;
  line-height: 107%;
  font-weight: 700;
  margin-bottom: 10px;
  color: #000;
}

.options__list {
  list-style: inside;
}

.options__list-item {
  font-weight: 600;
  font-size: 28px;
  line-height: 122%;
  margin-bottom: 10px;
}

.white-text {
  color: var(--white);
}

.services-page__title {
  color: var(--red);
}

@media (max-width: 500px) {
  .services-page__title {
    color: var(--red);
    margin-bottom: 20px;
    max-width: 219px;
  }

  .options {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .options__block {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 237px);
    grid-column-gap: 0px;
    grid-row-gap: 15px;
  }

  .options__item {
    border-radius: 8px;
    padding: 15px 15px 15px 20px;
  }

  .options__title {
    font-size: 14px;
    line-height: 107%;
  }

  .options__list-item {
    font-size: 12px;
    line-height: 115%;
    margin-bottom: 5px;
  }
}
