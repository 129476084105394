.technical-capabilities {
  margin-top: 150px;
}

.technical-capabilities__title {
  margin-bottom: 60px;
}

.technical-img-mobile {
  display: none;
}

@media (max-width: 500px) {
  .technical-capabilities {
    margin-top: 40px;
  }

  .technical-capabilities__title {
    margin-bottom: 15px;
    max-width: 268px;
  }

  .technical-img {
    display: none;
  }

  .technical-img-mobile {
    display: block;
  }
}
