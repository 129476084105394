.button {
  width: 206px;
  height: 37px;
  border-radius: 28px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--white);
  border: 1px solid var(--white);
}

.icon {
  margin-left: 15px;
}

@media (max-width: 1024px) {
  .icon {
    margin-left: 5px;
    width: 10px;
  }
}

@media (max-width: 500px) {
  .header-button {
    display: none;
  }

  .icon {
    margin-left: 5px;
    width: 10px;
  }
}
