@font-face {
  font-family: 'Furore';
  font-style: normal;
  font-weight: 400;
  src: local('Furore'), url('../fonts/FURORE.woff2') format('woff2'),
    url('../fonts/FURORE.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), url('../fonts/Raleway-Regular.woff2') format('woff2'),
    url('../fonts/Raleway-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local('Raleway'), url('../fonts/Raleway-Medium.woff2') format('woff2'),
    url('../fonts/Raleway-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway'), url('../fonts/Raleway-SemiBold.woff2') format('woff2'),
    url('../fonts/Raleway-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local('Raleway'), url('../fonts/Raleway-Bold.woff2') format('woff2'),
    url('../fonts/Raleway-Bold.woff') format('woff');
}

:root {
  --back: #21242e;
  --red: #ff0000;
  --whiteLightness: 100%;
  --white: hsl(0, 0%, var(--whiteLightness));
  --grayF0: hsl(0, 0%, calc(var(--whiteLightness) - 6%));
  --grayF1: hsl(0, 0%, calc(var(--whiteLightness) - 5%));
  --grayF6: hsl(0, 0%, calc(var(--whiteLightness) - 4%));
  --grayEC: hsl(0, 0%, calc(var(--whiteLightness) - 7%));
  --grayE4: hsl(0, 0%, calc(var(--whiteLightness) - 11%));
  --grayD9: hsl(0, 0%, calc(var(--whiteLightness) - 15%));
  --lightPink: hsl(0, 30%, calc(var(--whiteLightness) - 5%));
  --mediumPink: hsl(0, 86%, calc(var(--whiteLightness) - 15%));
  --pink: hsl(0, 95%, calc(var(--whiteLightness) - 24%));
  --lightRed: hsl(0, 100%, calc(var(--whiteLightness) - 38%));
}

body {
  padding: 0;
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  background-color: var(--white);
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

* {
  color: var(--black);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

.content {
  position: absolute;
  top: 850px;
  width: 100%;
  margin: 0 auto;
}

.global-title {
  font-family: 'Furore', sans-serif;
  font-size: 32px;
  font-weight: 500;
  color: var(--red);
  text-transform: uppercase;
}

.news {
  margin-top: 250px;
  margin-bottom: 30px;
}

.media {
  margin-bottom: 100px;
}

.servicesnew {
  margin-top: 250px;
  margin-bottom: 50px;
}

.news-mobile {
  display: none;
}

.media-mobile {
  display: none;
}

.servicesnew-mobile {
  display: none;
}

.media-mobile-content {
  display: none;
}

.servicesnew-text {
  text-align: center;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 100px;
}

.servicesnew-text-link {
  display: block;
  color: #ff0000;
}

.page-title {
  padding-top: 200px;
  margin-bottom: 100px;
}

.new-one{
  margin-bottom: 100px;
}

.new-one-slider{
  max-width: 1024px;
  margin: 0px auto 40px auto;
}

.new-one-slide{
  position: relative;
  width: 100%;
  aspect-ratio: 1280/780;
}

.new-one-slide img{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.new-one-title {
  /* padding-left: 70px; */
  padding: 0px 12px;
  font-weight: 700;
  font-size: 70px;
  line-height: normal;
  margin-bottom: 40px;
}

.new-one-main-text {
  /* padding-left: 70px; */
  padding: 0px 12px;
  font-size: 36px;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 50px;
  max-width: 1088px;
}

.new-one-text {
  font-size: 32px;
  line-height: normal;
  max-width: 1357px;
  /* padding-left: 70px; */
  padding: 0px 12px;
  margin-bottom: 20px;
}

.new-one-main-img {
  margin-bottom: 50px;
}

.img-wrapper {
  position: relative;
}
.img-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@media (max-width: 1024px) {
  .content {
    top: 340px;
  }

  .global-title {
    font-size: 16px;
    line-height: normal;
  }
}

@media (max-width: 500px) {
  .content {
    top: 313px;
  }

  .global-title {
    font-size: 16px;
  }

  .news {
    display: none;
  }

  .news-mobile {
    padding-top: 70px;
    padding-bottom: 20px;
    display: block;
  }

  .media {
    display: none;
  }

  .media-mobile {
    padding-top: 30px;
    display: block;
    padding-bottom: 20px;
    margin: 0 auto;
  }

  .servicesnew-text {
    margin-bottom: 70px;
  }

  .servicesnew {
    display: none;
  }

  .servicesnew-mobile {
    display: block;
    padding: 80px 0;
    padding-bottom: 20px;
  }

  .media-mobile-content {
    display: block;
    margin: 0 auto;
  }

  .media-pdf {
    margin: 0 auto;
  }

  .page-title {
    padding-top: 70px;
    margin-bottom: 20px;
  }

  .new-one-title {
    font-weight: 600;
    /* padding-left: 20px; */
    font-size: 24px;
    margin-bottom: 40px;
  }

  .new-one-main-text {
    font-weight: 600;
    /* padding-left: 20px; */
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 278px;
  }

  .new-one-text {
    /* padding-left: 20px; */
    margin-bottom: 20px;
    font-size: 17px;
    line-height: normal;
    max-width: 276px;
  }

  .new-one-main-img {
    width: 315px;
    margin-bottom: 20px;
  }
}
