.services-groups {
  position: relative;
  background: url(../../img/servicesmainintro.png) no-repeat center;
  background-size: cover;
  margin-bottom: 400px;
}

.services-group-content {
  padding-top: 350px;
  padding-bottom: 120px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 596px;
  grid-column-gap: 35px;
  grid-row-gap: 0px;
}

.services-group-item {
  position: relative;
  padding: 75px 55px 63px 55px;
  background-color: #f0f0f0;
  border-radius: 20px;
}

.services-group-item:hover .services-group-number,
.services-group-item:hover .services-group-title {
  color: var(--white);
}

.services-group-item:hover .services-group-button {
  transform: rotate(-90deg);
}

.services-group-item:hover .services-group-button svg circle {
  fill: var(--red);
}

.services-group-number {
  font-family: 'Furore', sans-serif;
  font-size: 48px;
  line-height: normal;
  font-weight: 500;
  color: #d9d9d9;
}

.services-group-title {
  font-family: 'Furore', sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
  color: var(--red);
  text-transform: uppercase;
}

.services-group-button {
  position: absolute;
  bottom: 63px;
  right: 63px;
}

.services-group-title-first:hover {
  background: url('../../img/services-first.png') no-repeat center;
  background-size: cover;
}

.services-group-title-second:hover {
  background: url('../../img/services-second.png') no-repeat center;
  background-size: cover;
}

.services-blur-img {
  position: absolute;
  bottom: -330px;
  left: -150px;
  max-width: 100%;
}

@media (max-width: 500px) {
  .services-groups {
    margin-bottom: 50px;
  }

  .services-group-content {
    padding-top: 85px;
    padding-bottom: 200px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 225px);
    grid-column-gap: 0px;
    grid-row-gap: 15px;
  }

  .services-group-item {
    border-radius: 8px;
    padding: 28px;
  }

  .services-group-title {
    font-size: 15px;
  }

  .services-group-number {
    font-size: 18px;
  }
  .services-group-button {
    transform: rotate(-90deg);
    right: 28px;
    bottom: 0px;
  }

  .services-group-button svg {
    width: 26px;
    fill: var(--red);
  }

  .services-group-button svg circle {
    fill: var(--red);
  }

  .services-blur-img {
    display: none;
  }
}
