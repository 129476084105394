.tagline {
  background-color: var(--white);
  padding: 180px 200px 200px 200px;
  border-radius: 20px;
  margin-bottom: 25px;
}

.tagline__title {
  text-align: center;
  font-family: 'Furore', sans-serif;
  font-size: 60px;
  line-height: 132%;
  color: var(--black);
}

@media (max-width: 1024px) {
  .tagline {
    padding: 80px;
    border-radius: 8px;
  }

  .tagline__title {
    font-size: 24px;
  }
}

@media (max-width: 500px) {
  .tagline {
    padding: 36px 20px 57px 20px;
    margin-bottom: 0;
  }

  .tagline__title {
    font-size: 16px;
    line-height: normal;
  }
}
