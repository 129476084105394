.application__block {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 188px;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}

.application-item {
  border-radius: 20px;
  padding-top: 10px;
  padding-left: 15px;
  background-color: #f6f6f6;
}

.application__number {
  font-family: 'Furore', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 122%;
  margin-bottom: 60px;
  text-transform: uppercase;
}

.application__number-fisrt {
  color: #ffc7c7;
}

.application__number-second {
  color: #ffa1a1;
}

.application__number-third {
  color: #ff7b7b;
}

.application__number-fourth {
  color: #ff3c3c;
}

.application__number-fifth {
  color: #ff0000;
}

.application-item-first {
  background-color: #f6f6f6;
}

.application-item-second {
  background-color: #f0f0f0;
  padding-right: 40px;
}

.application-item-third {
  background-color: #ececec;
  padding-right: 40px;
}

.application-item-fourth {
  background-color: #e4e4e4;
  padding-right: 40px;
}

.application-item-fifth {
  background-color: #d9d9d9;
}

.application__text {
  font-size: 16px;
  font-weight: 600;
  line-height: 122%;
}

@media (max-width: 500px) {
  .application__block {
    margin-top: 15px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 70px);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
  }

  .application__number {
    font-size: 19px;
    line-height: 122%;
    font-weight: 600;
  }

  .application__text {
    font-size: 11px;
    line-height: 115%;
  }

  .application-item {
    padding: 10px;
    padding-top: 0px;
    border-radius: 8px;
  }
}
