.layout {
  position: relative;
  max-width: 1459px;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .layout {
    max-width: 586px;
  }
}

@media (max-width: 500px) {
  .layout {
    max-width: 316px;
    margin: 0 auto;
  }
}
