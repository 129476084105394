.video {
  margin-top: 175px;
  margin-bottom: 200px;
}

.video-content {
  max-width: 846px;
}

@media (max-width: 500px) {
  .video {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .video-content {
    max-width: 314px;
  }
}
