.about-us {
  margin-top: 140px;
  margin-bottom: 158px;
}

.about-us__title {
  font-family: 'Furore', sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 122%;
  margin-bottom: 60px;
  color: #000;
  max-width: 967px;
}

.about-us__content {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 0px;
}

.about-us__block {
  padding: 42px;
  border: 2px solid var(--red);
  border-radius: 20px;
  background-color: rgba(243, 243, 243, 1);
}

.first-text-item {
  max-width: 451px;
}

.about-us__text {
  font-size: 40px;
  line-height: 120%;
  color: #000;
}

.about-us__img {
  position: absolute;
  left: 477px;
  top: 76px;
}

@media (max-width: 500px) {
  .about-us {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .about-us__title {
    font-size: 13px;
    margin-bottom: 25px;
  }

  .about-us__content {
    grid-template-columns: repeat(2, 155px);
    grid-column-gap: 5px;
  }

  .about-us__block {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid var(--red);
  }

  .about-us__text {
    font-size: 8px;
    line-height: 122%;
  }

  .about-us__img {
    left: 105px;
    top: -106px;
  }

  .about-us__img svg {
    width: 64px;
  }

  .first-text-item {
    max-width: 91px;
  }
}
