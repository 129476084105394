.industry {
  background: url(../../img/industryAbout.png) no-repeat center;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 120px;
}

.industry__title {
  font-family: 'Furore', sans-serif;
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--white);
  line-height: normal;
  margin-bottom: 60px;
}

.industry__content {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, 183px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.industry__block {
  border-radius: 23px;
  cursor: pointer;
}

.industry__block:hover .industry__text {
  color: #a5a5a5;
}

.first-child {
  grid-area: 1 / 1 / 3 / 5;
  background-color: var(--white);
  padding: 164px 94px 33px 46px;
}

.first-child:hover {
  background: url(../../img/first-block.png) no-repeat center;
  background-size: cover;
}

.second-child {
  grid-area: 3 / 1 / 5 / 5;
  background-color: var(--red);
  padding: 230px 74px 72px 46px;
}

.second-child:hover {
  background: url(../../img/second-block.png) no-repeat center;
  background-size: cover;
}

.third-child {
  grid-area: 1 / 5 / 4 / 8;
  background-color: #000;
  padding: 416px 52px 37px 46px;
}

.third-child:hover {
  background: url(../../img/third-block.png) no-repeat center;
  background-size: cover;
}

.fourth-child {
  grid-area: 4 / 5 / 5 / 11;
  background-color: var(--white);
  padding: 32px 250px 35px 46px;
}

.fourth-child:hover {
  background: url(../../img/fourth-block.png) no-repeat center;
  background-size: cover;
}

.fifth-child {
  grid-area: 1 / 8 / 2 / 11;
  background-color: var(--white);
  padding: 110px 98px 17px 30px;
}

.fifth-child:hover {
  background: url(../../img/fifth-block.png) no-repeat center;
  background-size: cover;
}

.sixth-child {
  grid-area: 2 / 8 / 3 / 11;
  background-color: var(--red);
  padding: 110px 39px 17px 30px;
}

.sixth-child:hover {
  background: url(../../img/six-block.png) no-repeat center;
  background-size: cover;
}

.seventh-child {
  grid-area: 3 / 8 / 4 / 11;
  background-color: #000;
  padding: 45px 39px 25px 30px;
}

.seventh-child:hover {
  background: url(../../img/seventh-block.png) no-repeat center;
  background-size: cover;
}

.industry__text {
  font-weight: 600;
  font-size: 46px;
  line-height: 122%;
  letter-spacing: -2px;
  color: var(--white);
}

.main-text {
  font-size: 73px;
}

.middle-text {
  font-size: 55px;
}

.red-color {
  color: var(--red);
}

@media (max-width: 500px) {
  .industry {
    padding: 15px 25px 22px 25px;
  }

  .industry__title {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .industry__content {
    grid-template-rows: repeat(4, 34px);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }

  .industry__block {
    border-radius: 4px;
  }

  .industry__text {
    font-size: 9px;
    letter-spacing: -0.5px;
  }

  .main-text {
    font-size: 15px;
    letter-spacing: -0.5px;
  }

  .middle-text {
    font-size: 11px;
    letter-spacing: normal;
  }

  .first-child {
    padding: 30px 14px 8px 8px;
  }

  .second-child {
    padding: 38px 14px 16px 8px;
  }

  .third-child {
    padding: 76px 11px 10px 9px;
  }

  .fourth-child {
    padding: 6px 29px 11px 9px;
  }

  .fifth-child {
    padding: 21px 21px 3px 6px;
  }

  .sixth-child {
    padding: 21px 3px 3px 6px;
  }

  .seventh-child {
    padding: 6px 11px 5px 6px;
  }
}
