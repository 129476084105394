.news-main-content {
  padding-top: 250px;
  padding-bottom: 140px;
}

.news-main-item {
  background: url('../../img/news-img-first-item.png') no-repeat center;
  background-size: cover;
  border-radius: 20px;
  padding-top: 100px;
  padding-left: 50px;
  padding-bottom: 200px;
  margin-bottom: 60px;
}

.news-main-title {
  font-family: 'Furore', sans-serif;
  font-size: 64px;
  font-weight: 500;
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 100px;
}

.news-main-block {
  background-color: var(--white);
  border-radius: 20px;
  max-width: 628px;
  padding: 40px;
}

.news-main-text {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}

/* news-main-dual */
/* news-main-content-block */
/* news-main-cover-wrapper */
/* news-main-content-block-text */
/* news-main-content-title */
/* news-main-content-text */
/* bottom-news-block */
/* news-main-content-more-text */

.news-main-dual {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  margin-bottom: 90px;
}

.news-main-content-block {
  position: relative;
  aspect-ratio: 696/614;
  flex: 0 1 calc((100% - 50px) / 2);
}

.news-main-cover-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.news-main-cover-wrapper img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-main-content-block-text {
  position: absolute;
  bottom: -50px;
  left: -20px;
  max-width: 560px;
  padding: 40px 40px 40px 50px;
  border-radius: 20px;
  background-color: var(--white);
}

.news-main-content-title {
  font-weight: 600;
  font-size: 32px;
  line-height: normal;
  margin-bottom: 10px;
}

.news-main-content-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: normal;
  margin-bottom: 15px;
}

.bottom-news-block {
  display: flex;
  justify-content: space-between;
}

.news-main-content-more-text {
  font-size: 28px;
  line-height: normal;
}

.news-main-content-more-text:hover {
  color: var(--red);
}

.news-main-triple {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.news-main-triple .news-main-content-block {
  position: relative;
  aspect-ratio: 458/403;
  flex: 0 1 calc((100% - 40px) / 3);
}

.news-main-triple .news-main-content-block-text {
  position: static;
  max-width: none;
  padding: 40px 5px 30px 5px;
}

@media(max-width:1024px) {
  .news-main-content {
    padding-top: 70px;
    padding-bottom: 120px;
  }

  .news-main-dual {
    flex-wrap: wrap;
    gap: 110px;
    margin-bottom: 110px;
  }

  .news-main-content-block {
    flex: 0 1 100%;
  }

  .news-main-content-block-text,
  .news-main-triple .news-main-content-block-text {
    position: absolute;
    bottom: -80px;
    left: -10px;
    max-width: 100%;
    padding: 20px 12px 12px 12px;
    border-radius: 8px;
  }

  .news-main-triple {
    flex-wrap: wrap;
    gap: 110px;
  }

  .news-main-triple .news-main-content-block {
    flex: 0 1 100%;
  }
}

@media (max-width: 500px) {
  .news-main-title {
    font-size: 12px;
    line-height: normal;
    margin-bottom: 15px;
  }

  .news-main-item {
    background: url('../../img/news-img-first-item.png') no-repeat center;
    background-size: cover;
    border-radius: 10px;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 60px;
    margin-bottom: 20px;
  }

  .news-main-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  .news-main-block {
    max-width: 278px;
    padding: 15px;
  }

  .news-main-content-title {
    font-size: 16px;
  }

  .news-main-content-text {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .news-main-content-more-text {
    font-size: 14px;
  }
}