.logo {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .logo svg {
    width: 39px;
    height: 37.4px;
  }
}

@media (max-width: 500px) {
  .logo svg {
    width: 39px;
    height: 37.4px;
  }
}
