@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.contacts {
  margin-top: 175px;
  display: flex;
  margin-bottom: 140px;
}

.contacts__content {
  max-width: 565px;
}

.contacts__title {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 60px;
}

.contacts__text {
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  line-height: 38px;
}

.contacts__button {
  margin-top: 60px;
  border: 1px solid var(--lightRed);
  background-color: var(--lightRed);
}

.contacts__map {
  margin-right: 200px;
}

.contacts__title-mobile {
  display: none;
}

@media (max-width: 1024px) {
  .contacts__button {
    margin-top: 15px;
    font-size: 14px !important;
    width: 148px !important;
    height: 22px !important;
  }

  .contacts {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .contacts__map {
    margin-right: 80px;
  }

  .contacts__map svg {
    width: 239px;
    height: 311px;
  }

  .contacts__title {
    display: none;
  }

  .contacts__title-mobile {
    display: block;
    margin-bottom: 10px;
  }

  .contacts__text {
    font-size: 16px;
    line-height: normal;
  }

  .contacts__button-mobile {
    background-color: var(--red) !important;
  }
}

@media (max-width: 500px) {
  .contacts {
    margin-top: 70px;
    display: block;
    margin-bottom: 40px;
  }

  .contacts__map svg {
    width: 161px;
    height: 181px;
  }

  .contacts__text {
    font-size: 16px;
    line-height: normal;
  }

  .contacts__text:not(:last-child) {
    margin-bottom: 10px;
  }

  .contacts__button-mobile {
    display: none;
  }

  .contacts__map {
    display: none;
  }
}
