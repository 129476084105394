.career {
  position: relative;
  padding-top: 158px;
  padding-bottom: 240px;
}

.career__title {
  color: var(--red);
}

.career__tagline {
  position: absolute;
  font-size: 64px;
  font-weight: 800;
  line-height: 105%;
  text-transform: uppercase;
  top: 150px;
  left: 336px;
}

.career__tagline span {
  display: block;
}

.career__block {
  padding-left: 336px;
}

.career__text {
  font-size: 20px;
  line-height: normal;
  font-weight: 500;
  text-indent: 10px;
}

.career__subtitle {
  font-family: 'Furore', sans-serif;
  font-weight: 700;
  font-size: 50px;
  line-height: 121%;
  letter-spacing: -1px;
  color: var(--red);
  margin-bottom: 35px;
}

.career__subtitle span {
  display: block;
}

.first-span {
  padding-left: 250px;
}

.career__content {
  display: flex;
  margin-bottom: 145px;
}

.career__img {
  margin-right: 60px;
}

.career__main-text {
  margin-top: 45px;
  font-weight: 600;
  max-width: 300px;
}

.career__items {
  display: grid;
  grid-template-columns: repeat(3, 325px);
  grid-template-rows: repeat(2, 350px);
  grid-column-gap: 66px;
  grid-row-gap: 66px;
  place-items: center;
}

.career__item {
  display: flex;
  flex-direction: column;
}

.career__item-text {
  margin-top: 46px;
  max-width: 324px;
}

.career__item-first {
  grid-area: 1 / 1 / 2 / 2;
}

.career__item-second {
  grid-area: 2 / 1 / 3 / 2;
}

.career__item-third {
  grid-area: 1 / 2 / 3 / 3;
}

.career__item-fourth {
  grid-area: 1 / 3 / 2 / 4;
}

.career__item-fifth {
  grid-area: 2 / 3 / 3 / 4;
}

.career__primary-color {
  color: var(--red);
  font-weight: 600;
}

.career__line {
  display: flex;
  justify-content: space-between;
}

.career__item-text-today {
  margin-top: 0px;
  font-family: 'Montserrat', sans-serif;
}

.career__item-text-today-last {
  margin-top: 50px;
}

.career__line-second {
  margin-top: 30px;
}

.career__block-flex {
  display: flex;
  justify-content: space-between;
}

.career__item-text-people {
  max-width: 661px;
}

.career-title {
  margin-top: 100px;
}

.career__button-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--red);
  border-radius: 22px;
  width: 200px;
  height: 37px;
  font-size: 20px;
  color: #fff;
}

.career__button-link svg {
  margin-right: 10px;
}

.career__item-text-people-last {
  max-width: 420px;
}

.career__content-people-last {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.career-rectangle {
  margin-top: 50px;
  padding: 50px;
  border-radius: 48px;
  background-color: #f0f0f0;
}

.career-rectangle-top {
  display: flex;
  justify-content: end;
  margin-bottom: 30px;
}

.career-rectangle-title {
  font-family: 'Furore', sans-serif;
  font-weight: 700;
  font-size: 46px;
  line-height: 90%;
  color: #000;
  max-width: 690px;
  text-align: right;
  margin-right: 30px;
}

.career-rectangle-subtitle {
  font-family: 'Furore', sans-serif;
  color: transparent;
  -webkit-text-stroke: 1px #000;
  font-weight: 700;
  font-size: 96px;
  line-height: 95%;
  text-transform: uppercase;
}

.career-rectangle-bottom {
  display: flex;
  justify-content: space-between;
}

.career-rectangle-bottom-block {
  font-family: 'Montserrat', sans-serif;
  font-size: 29px;
  line-height: 112%;
  font-weight: 600;
  background-color: #fff;
  border-radius: 20px;
  padding: 40px 20px;
  max-width: 436px;
}

.career-rectangle-bottom-block-first {
  background-color: var(--red);
  color: #fff;
}

.career-rectangle-bottom-block-second {
  background-color: #000;
  color: #fff;
}

.career-rectangle-bottom-primary {
  text-transform: uppercase;
}

.career-rectangle-title-second {
  max-width: 300px;
}

@media (max-width: 500px) {
  .career {
    padding-top: 35px;
    padding-bottom: 40px;
  }

  .career__tagline {
    top: 36px;
    left: 80px;
    font-size: 14px;
  }

  .career__block {
    padding-left: 15px;
  }

  .career__text {
    font-size: 10px;
    text-indent: 0px;
    font-weight: 400;
  }

  .career__subtitle {
    font-size: 12px;
    line-height: 122%;
    letter-spacing: normal;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .first-span {
    padding-left: 0px;
  }

  .career__content {
    flex-direction: column;
  }

  .career__img {
    margin-right: 0px;
    width: 235px;
    height: 110px;
  }

  .career__main-text {
    display: none;
  }

  .career__content {
    margin-bottom: 40px;
  }

  .career__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 150px);
    grid-column-gap: 5px;
    grid-row-gap: 35px;
    place-items: center;
  }

  .career__item svg {
    width: 32px;
    height: auto;
  }

  .career__item-text {
    margin-top: 10px;
    font-size: 7px;
    line-height: 104%;
    max-width: 75px;
  }

  .career__title {
    margin-bottom: 10px;
  }

  .career__line {
    display: block;
  }

  .career__item:not(:last-child) {
    margin-bottom: 30px;
  }

  .career__item-text-today {
    max-width: 335px;
    font-size: 16px;
  }

  .career__item-text-today-last {
    margin-top: 30px;
  }
  .career-title {
    margin-top: 50px;
  }

  .career__block-flex {
    display: block;
  }

  .career__item-text-people-last {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .career-rectangle {
    padding: 15px;
    border-radius: 20px;
  }

  .career-rectangle-top {
    display: block;
    margin-bottom: 20px;
  }

  .career-rectangle-title {
    display: inline;
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    text-align: left;
    margin-right: 5px;
  }

  .career-rectangle-subtitle {
    display: inline;
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
  }

  .career-rectangle-bottom {
    display: block;
  }

  .career-rectangle-bottom-block {
    padding: 10px;
    font-size: 14px;
    line-height: normal;
    height: 70px;
    max-width: 100%;
    border-radius: 8px;
  }

  .career-rectangle-bottom-block:not(:last-child) {
    margin-bottom: 10px;
  }
}
