.services {
  position: relative;
  margin-top: 175px;
}

.services__img-content {
  margin-top: 90px;
  position: relative;
  background: url(../../img/services-background.png) center no-repeat;
  background-size: cover;
  filter: blur(21%);
  height: 919px;
}

.services__tagline {
  position: absolute;
  font-family: 'Furore', sans-serif;
  font-size: 48px;
  line-height: normal;
  font-weight: 400;
  letter-spacing: -2%;
  color: #000;
  text-transform: uppercase;
  top: 0;
  left: 790px;
  z-index: 99999;
}

.services__img {
  position: relative;
  display: block;
  max-width: 1223px;
  margin: 0 auto;
}

.blur {
  position: absolute;
  top: -80px;
  height: 164px;
  width: 100vw;
  background-color: var(--white);
  filter: blur(21px);
}

.blur-two {
  top: 780px;
}

.services__content {
  padding-top: 94px;
  padding-left: 147px;
  padding-right: 195px;
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
  border-radius: 20px;
}

.services__text {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  max-width: 548px;
}

.services__text-block {
  max-width: 530px;
}

.services__button-block {
  display: flex;
}

.services__button {
  margin-top: 60px;
  margin-left: 20px;
  color: #000;
  border-color: #000;
}

.absolute-block {
  position: absolute;
  width: 100%;
  bottom: -100px;
}

.services-bottom-content {
  margin-top: 200px;
  display: block;
}

.tagline-bottom {
  margin-bottom: 30px;
}

.tagline-bottom-block {
  left: 980px;
}

@media (max-width: 1590px) {
  .services__img {
    left: 10%;
  }

  .services__tagline {
    left: 50%;
  }

  .services-bottom {
    padding-left: 0;
  }
}

@media (max-width: 1024px) {
  .services {
    margin-top: 50px;
  }

  .absolute-block {
    bottom: -190px;
  }

  .services__tagline {
    padding-top: 3px;
    font-size: 24px;
    line-height: normal;
    left: 250px;
  }

  .services__img {
    width: 489px;
    height: 268px;
    margin-top: 20px;
    left: 0px;
  }

  .services__img-content {
    height: 320px;
    margin-top: 20px;
  }

  .services__content {
    padding-top: 17px;
    padding-left: 18px;
    padding-right: 13px;
  }

  .services__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    max-width: 293px;
  }

  .services__text-first {
    margin-bottom: 15px;
  }

  .services__button {
    margin-top: 15px;
    border-radius: 11px;
    width: 112px !important;
    height: 22px;
    font-size: 14px;
  }

  .blur {
    display: none;
  }

  .services__text-block {
    max-width: 259px;
  }
}

@media (max-width: 500px) {
  .services__img-content {
    display: flex;
    justify-content: center;
    height: 217px;
    margin-top: 20px;
  }

  .absolute-block {
    bottom: -350px;
  }

  .services__tagline {
    font-size: 20px;
    left: 56%;
  }

  .services__img {
    width: 319px;
    height: 175px;
    position: relative;
    left: 0px;

    margin-top: 20px;
  }

  .blur {
    display: none;
  }

  .services__content {
    flex-direction: column;
    padding-top: 17px;
    padding-left: 18px;
    padding-right: 13px;
  }

  .services__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .services__text-first {
    margin-bottom: 15px;
  }

  .services__button {
    margin-top: 15px;
    border-radius: 11px;
    width: 112px !important;
    height: 22px;
    font-size: 14px;
  }
}
