.contacts-block {
  margin-top: 0;
  padding-top: 200px;
}

.map {
  display: none;
}

@media (max-width: 500px) {
  .contacts-block {
    padding-top: 85px;
  }

  .contact-block-title {
    display: block;
    font-family: 'Furore', sans-serif;
    font-size: 14px;
    line-height: normal;
    color: var(--red);
    margin-bottom: 15px;
  }

  .contact-block-btn {
    display: none;
  }

  .map {
    display: block;
    width: 100%;
  }
}
