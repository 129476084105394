@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.footer {
  background: url(../../img/footer.png) center no-repeat;
  background-size: cover;
  height: 303px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.footer__logo {
  margin-bottom: 20px;
}

.footer__contact {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  font-size: 24px;
  color: var(--white);
}

.footer__contact p:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 1024px) {
  .footer {
    height: 121px;
    width: 100vw;
  }

  .footer__logo {
    margin-bottom: 5px;
  }

  .footer__logo svg {
    width: 116px;
    height: 62px;
  }

  .footer__contact p {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .footer {
    height: 130px;
  }
  .footer__logo svg {
    width: 58px;
    height: 22px;
  }

  .footer__contact {
    flex-direction: column;
  }

  .footer__contact p,
  .footer__contact a {
    font-size: 12px;
  }

  .footer__contact p:first-child {
    margin-right: 10px;
  }

  .footer__logo {
    margin-bottom: 0px;
  }
}
