.not-found {
  padding-top: 343px;
  height: 100vh;
}

.not-found-title {
  font-family: 'Furore', sans-serif;
  font-weight: 400;
  font-size: 128px;
  line-height: normal;
  margin-bottom: 20px;
}

.not-found-text {
  font-family: 'Raleway', sans-serif;
  font-size: 32px;
  line-height: normal;
  max-width: 919px;
}

.not-found-link {
  padding-left: 5px;
  color: var(--red);
}

@media (max-width: 500px) {
  .not-found {
    padding-top: 140px;
    height: 92vh;
  }

  .not-found-title {
    font-size: 38px;
    margin-bottom: 10px;
  }

  .not-found-text {
    font-size: 10px;
    max-width: 290px;
  }
}
