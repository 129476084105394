.services-intro__img {
  margin-top: 255px;
  margin-left: 125px;
  margin-bottom: 115px;
}

.services-intro__title {
  color: #000;
  line-height: normal;
}

.services-intro-image {
  display: block;
  margin: 0 auto;
  margin-bottom: 115px;
}

.first-services-title {
  max-width: 731px;
}

.second-services-title {
  max-width: 1028px;
}

.third-services-title {
  max-width: 1043px;
}

.fourth-services-title {
  max-width: 1000px;
}

.services-intro-image-second {
  width: 100%;
}

.services-intro-image-third {
  width: 100%;
}

.services-intro-fourth {
  width: 100%;
}

@media (max-width: 1024px) {
  .services-intro-image-fisrt {
    max-width: 559px;
  }
}

@media (max-width: 500px) {
  .services-intro__img {
    margin-top: 70px;
    margin-left: 0px;
    width: 100%;
    margin-bottom: 50px;
  }

  .services-intro-image-fisrt {
    max-width: 100%;
  }

  .services-intro-image {
    margin-bottom: 50px;
  }
}
