.services-bottom {
  margin-top: 250px;
  padding-left: 147px;
}

.services-bottom-tagline {
  font-family: 'Furore', sans-serif;
  font-size: 48px;
  line-height: normal;
  font-weight: 400;
  margin-bottom: 40px;
}

.services-bottom-tagline span {
  display: block;
}

.services-bottom-fisrt-span {
  text-align: right;
}

.services-bottom__content {
  display: flex;
}

.services-bottom__img {
  margin-right: 80px;
}

.services-bottom__block {
  padding-top: 120px;
}

.services-bottom__text {
  font-size: 24px;
  line-height: normal;
  margin-bottom: 130px;
  max-width: 406px;
}

.services__button {
  width: 148px;
}

.services-contact-button {
  background-color: var(--red);
}

.services__button-white {
  color: #000;
  border: 1px solid #000;
}

@media (max-width: 1024px) {
  .services__button-white {
    width: 112px;
    height: 22px;
    font-size: 14px;
  }

  .services-bottom {
    margin-top: 200px;
    padding-left: 0;
  }

  .services-bottom-tagline {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .services-bottom__img {
    width: 309px;
    margin-right: 20px;
  }

  .services-bottom__block {
    padding-top: 15px;
  }

  .services-bottom__text {
    font-size: 12px;
    line-height: normal;
    margin-bottom: 0px;
  }
}

@media (max-width: 500px) {
  .services-bottom {
    margin-top: 370px;
    padding-left: 0;
  }

  .services-bottom-tagline {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .services-bottom__content {
    flex-direction: column;
  }

  .services-bottom__img {
    margin-right: 0;
  }

  .services-bottom__block {
    padding-top: 15px;
  }

  .services-bottom__text {
    font-size: 12px;
    line-height: normal;
    margin-bottom: 0px;
  }

  .services__button-white {
    width: 112px;
    height: 22px;
    font-size: 14px;
  }
}
