.aboutintro {
  background: url(../../img/introAbout.png) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 823px;
}

.aboutintro_button {
  background-color: var(--red);
  border-color: var(--red);
}

.aboutintro__layout {
  position: absolute;
  background-color: rgba(2, 3, 3, 0.41);
  z-index: 999999999;
  width: 100%;
}

.aboutintro__intro {
  position: relative;
}

.aboutintro__content {
  padding-top: 535px;
  display: flex;
  max-width: 1459px;
  margin: 0 auto;
  align-items: center;
}

.aboutintro__title {
  font-family: 'Furore', sans-serif;
  font-size: 64px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--white);
  line-height: normal;
  margin-right: 40px;
}

.aboutintro__text {
  font-size: 34px;
  line-height: 133%;
  letter-spacing: -2px;
  color: var(--white);
}

@media (max-width: 768px) {
  .aboutintro_button {
    display: none;
    padding: 12px 0;
  }

  .aboutintro__content {
    padding-top: 73px;
    flex-direction: column;
    max-width: 316px;
    margin: 0 auto;
  }
}

@media (max-width: 500px) {
  .aboutintro {
    height: 352px;
  }

  .aboutintro__intro {
    padding: 12px 0;
  }

  .aboutintro__title {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .aboutintro__text {
    font-size: 17px;
    line-height: 122%;
    font-weight: 600;
    letter-spacing: normal;
  }
}
