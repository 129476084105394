.mission {
  position: relative;
}

.mission__content {
  margin-top: 220px;
  display: flex;
  margin-left: 300px;
}

.mission__text {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}

.mission__tagline {
  position: absolute;
  left: 300px;
}

.mission__tagline span:first-child {
  text-align: right;
}

.mission__tagline span {
  display: block;
  font-family: 'Furore', sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 121%;
  letter-spacing: -2%;
  color: #000;
  text-transform: uppercase;
}

.mission__img {
  margin-right: 40px;
}

.mission__text {
  font-size: 24px;
  font-weight: 400;
  width: 344px;
  color: #000;
}

@media (max-width: 1024px) {
  .mission__tagline {
    left: 50px;
  }

  .mission__tagline span:first-child {
    margin-left: 300px;
  }

  .mission__tagline span {
    font-size: 20px;
  }

  .mission__content {
    padding-left: 50px;
    margin-left: 0;
    margin-top: 120px;
  }

  .mission__img {
    width: 260px;
    height: 220px;
    margin-bottom: 10px;
  }

  .mission__text {
    font-weight: 500;
    font-size: 16px;
    line-height: normal;
    max-width: 259px;
  }
}

@media (max-width: 500px) {
  .mission__title {
    margin-bottom: 12px;
  }

  .mission__tagline {
    position: relative;
    left: 0px;
  }

  .mission__tagline span:first-child {
    margin-left: 0;
  }

  .mission__tagline span {
    font-weight: 600;
    font-size: 16px;
    line-height: 106%;
  }

  .mission__content {
    padding-left: 20px;
    flex-direction: column;
    margin-left: 0;
    margin-top: 15px;
  }

  .mission__img {
    width: 215px;
    height: 155px;
    margin-bottom: 10px;
  }

  .mission__text {
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    max-width: 293px;
  }
}
