.new {
  position: relative;

  flex: 0 1 calc((100% - 80px) / 3);
  aspect-ratio: 465/500;
  /* height: 500px; */
  /* background-color: var(--grayF1); */
  border-radius: 20px;
  padding-top: 50px;
  padding-left: 25px;
  padding-right: 60px;
  overflow: hidden;
}

.new img {
  position: absolute;
  z-index: -2;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.new::before{
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 100%;
  z-index:-1;
  background-color: var(--grayF1);
}

/* .new:hover {
  background: url(../../img/newintro.png) no-repeat center;
  background-size: cover;
} */

.new:hover::before {
  opacity: 0;
  visibility: hidden;
}

.new:hover .new__title {
  color: var(--white);
}

.new:hover .new__month,
.new:hover .new__year {
  color: var(--white);
}

.new__title {
  font-size: 40px;
  line-height: 117%;
  color: #000;
  font-weight: 500;
}

.new__date {
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  bottom: 40px;
  right: 20px;
}

.new__day {
  font-size: 96px;
  font-weight: 700;
  color: #ff0028;
  margin-right: 10px;
}

.new__date-block {
  display: flex;
  flex-direction: column;
}

.new__month,
.new__year {
  font-size: 38px;
  font-weight: 400;
  line-height: 100%;
  color: #000;
}

@media(max-width: 1200px){
  .new {
    flex: 0 1 calc((100% - 40px) / 2);
  }
}

@media (max-width: 1024px) {
  .new {
    height: 200px;
    border-radius: 7px;
    padding: 20px;
  }

  .new__title {
    font-size: 16px;
    line-height: 94%;
  }

  .new__date {
    bottom: 20px;
  }

  .new__day {
    font-size: 30px;
    line-height: normal;
  }

  .new__month,
  .new__year {
    font-size: 12px;
    line-height: 126%;
  }
}

@media (max-width: 500px) {
  .new {
    flex: 0 1 100%;
    padding: 10px;
  }
}
