.presentation__container {
  display: flex;
}

.presentation {
  margin-top: 175px;
  max-width: 645px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 20px;
}

.presentation:not(:last-child) {
  margin-right: 15px;
}

.presentation__img {
  margin-bottom: 40px;
  max-width: 100%;
}

.presentation__link {
  padding: 8px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: var(--white);
  background-color: var(--red);
  border-radius: 28px;
}

.presentation__link svg {
  padding-top: 7px;
  padding-left: 5px;
}

@media (max-width: 500px) {
  .presentation__container {
    display: flex;
    flex-direction: column;
  }

  .presentation {
    margin-top: 60px;
    max-width: 220px;
    padding: 10px;
    border-radius: 8px;
  }

  .presentation__img {
    margin-bottom: 20px;
  }
}
