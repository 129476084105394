.about {
  background: url('../../img/about.png') center no-repeat;
  background-size: cover;
  height: 793px;
  padding-left: 70px;
  padding-top: 178px;
  margin-bottom: 150px;
}

.about__content {
  background-color: var(--white);
  border-radius: 20px;
  padding-left: 55px;
  padding-top: 110px;
  padding-right: 90px;
  width: 677px;
  height: 438px;
}

.about__title {
  font-family: 'Furore', sans-serif;
  font-size: 32px;
  line-height: 104%;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}

.about__text {
  font-size: 24px;
  font-weight: 500;
}

@media (max-width: 1024px) {
  .about {
    padding: 75px 0;
    height: 317px;
    margin-bottom: 45px;
  }

  .about__content {
    padding: 20px;
    width: auto;
    height: auto;
  }

  .about__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 104%;
  }

  .about__text {
    font-size: 16px;
    line-height: normal;
  }
}

@media (max-width: 500px) {
  .about {
    padding: 75px 0;
    height: 317px;
    margin-bottom: 45px;
  }

  .about__title {
    font-size: 16px;
  }

  .about__text {
    font-size: 12px;
  }
}
