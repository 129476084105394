.servicesnew {
    margin-bottom: 50px;
    margin-top: 250px;
}
.servicesnew-mobile {
    display: none;
    margin: 0px auto;
}

.servicesnew-text {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 100px;
    text-align: center;
}

.servicesnew-text-link {
    color: red;
    display: block;
}

@media (max-width: 768px) {
    .servicesnew {
        display: none;
    }

    .servicesnew-mobile {
        display: block;
        padding: 80px 0 20px;
    }
}

@media (max-width: 500px) {
    .servicesnew-text {
        margin-bottom: 70px;
    }
}





