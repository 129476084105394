.certificate {
  position: relative;
  margin-top: 175px;
}

.certificate__img {
  width: 376px;
}

@media (max-width: 500px) {
  .certificate {
    margin-top: 60px;
  }

  .certificate__img {
    width: 154px;
  }
}
