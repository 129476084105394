.press-kit {
  padding-top: 258px;
  position: relative;
}

.press-kit__title {
  font-family: 'Furore', sans-serif;
  font-size: 64px;
  line-height: normal;
  margin-bottom: 35px;
}

.press-kit__text {
  font-size: 32px;
  line-height: normal;
  max-width: 760px;
}

.press-kit__laptop {
  position: absolute;
  right: 0;
  top: 60px;
}

@media (max-width: 500px) {
  .press-kit {
    padding-top: 130px;
  }

  .press-kit__title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .press-kit__text {
    font-size: 16px;
    max-width: 294px;
  }

  .press-kit__laptop {
    width: 180px;
    top: 40px;
    right: -10px;
  }
}
