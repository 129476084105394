.intro {
  background: url('../../img/intro.png') center no-repeat;
  background-size: cover;
  height: 990px;
  display: flex;
  flex-direction: column;
}

.intro__content {
  max-width: 1292px;
  margin: 0 auto;
  padding-top: 280px;
}

.intro__title {
  font-family: 'Furore', sans-serif;
  font-size: 83px;
  line-height: 109%;
  color: var(--white);
  margin-bottom: 30px;
}

.intro__title p:last-child {
  padding-left: 323px;
}

.intro__text {
  max-width: 1292px;
  color: var(--white);
  font-size: 32px;
  line-height: 40px;
}

@media (max-width: 1590px) {
  .intro__content {
    padding-left: 10%;
  }
}

@media (max-width: 1024px) {
  .intro {
    height: 397px;
  }

  .intro__content {
    padding-top: 80px;
    padding-left: 0;
    max-width: 585px;
    margin: 0 auto;
  }

  .intro__title {
    font-size: 36px;
    line-height: 107%;
    margin-bottom: 15px;
  }

  .intro__title p:last-child {
    padding-left: 252px;
  }

  .intro__text {
    font-size: 20px;
    line-height: normal;
    max-width: 556px;
  }
}

@media (max-width: 500px) {
  .intro {
    width: 100%;
    height: 391px;
  }

  .intro__title {
    font-size: 22px;
    line-height: 109%;
  }

  .intro__title p:last-child {
    padding-left: 93px;
  }

  .intro__content {
    padding: 0;
    max-width: 316px;

    padding-top: 73px;
  }

  .intro__title {
    margin-bottom: 10px;
  }

  .intro__text {
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    letter-spacing: -1px;
  }
}
