.menu {
  display: flex;
  width: 48.4%;
  align-items: center;
  justify-content: space-between;
}

.menu__link {
  color: var(--white);
}

@media (max-width: 768px) {
}

@media (max-width: 768px) {
  .menu {
    width: 290px;
  }

  .menu__link {
    font-size: 9px;
  }
}

@media (max-width: 1024px) {
  .menu {
    display: none;
  }
}
