.news-block {
  margin-top: 175px;
}

.news-block__title {
  margin-bottom: 70px;
}

.news-block__content {
  /* display: grid;
  grid-template-columns: repeat(3, 465px);
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 0px;
  margin-bottom: 40px; */
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 45px;
}

.news-block__button {
  padding: 10px 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border: 1px solid #000;
  border-radius: 28px;
}
.news-mobile-img {
  display: none;
}

/* .news-back:hover {
  background: url('../../img/news-back-one.png') no-repeat center !important;
  background-size: cover;
} */

@media (max-width: 1024px) {
  .news-block {
    margin-top: 60px;
  }

  .news-block__title {
    margin-bottom: 20px;
  }

  .news-block__content {
    grid-template-columns: repeat(3, 185px);
    grid-column-gap: 20px;
    margin-bottom: 20px;
  }

  .news-block__button {
    padding: 5px 20px;
    font-size: 14px;
    border-radius: 14px;
  }
}

@media (max-width: 500px) {
  .news-block {
    margin-top: 70px;
  }

  .news-block__title {
    margin-bottom: 20px;
  }

  .news-block__content {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 161px);
    grid-row-gap: 20px;
  }

  .news-mobile-img {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
