@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.cookie {
  max-width: 448px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 20px;
  border-radius: 19px;
  background-color: #ededed;
  z-index: 999999999999999999999999;
}

.cookie__text {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 120%;
  color: #21242e;
  margin-bottom: 10px;
}

.cookie__link {
  text-decoration: underline;
}

.cookie__button {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 120%;
  color: var(--red);
}

.cookie__button svg {
  margin-left: 4px;
}

.cookie-not-active {
  display: none;
}

@media (max-width: 1024px) {
  .cookie {
    max-width: 365px;
    border-radius: 16px;
    padding: 12px;
  }

  .cookie__text {
    font-size: 12px;
    line-height: 115%;
  }

  .cookie__button {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .cookie {
    max-width: 328px;
    right: 5px;
  }
}
